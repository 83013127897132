import React from "react"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import typographyStyle from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import InstagramEmbed from "react-instagram-embed"

const Instagram = ({ classes, posts }) => (
<div id="instagram">
    <br />
    <GridContainer
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      {posts ? posts.map(post => {
        return (
          <GridItem>
            <InstagramEmbed
              url={`https://www.instagram.com/p/${post}/`}
              maxWidth={'320px'}
              hideCaption={false}
              containerTagName="div"
              protocol=""
              injectScript
              onLoading={() => {}}
              onSuccess={() => {}}
              onAfterRender={() => {}}
              onFailure={() => {}}
            />
          </GridItem>
        )
      }) : <></>}
    </GridContainer>
  </div>
)

export default withStyles(typographyStyle)(Instagram)
